import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

import { actions as actionsGeral } from '@/modules/geral/storeGeral';
import { actions as actionsCandidate } from '@/modules/candidate/storeCandidate';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Início',
    },
  },
  //{
  //  path: '/cadastro',
  //  name: 'Cadastro',
  //  component: () => import(/* webpackChunkName: "cadastro" */ '../views/cadastro/index.vue'),
  //  meta: {
  //    title: 'Cadastro',
  //  },
  //},
  {
    path: '/pre-requisitos-cnh',
    name: 'PreRequisitosCNH',
    component: () => import(/* webpackChunkName: "prerequisitos-cnh" */ '../views/PreRequisitosCNH.vue'),
    meta: {
      title: 'Pré-requisitos CNH',
    },
  },
  {
    path: '/pre-requisitos-motociclista',
    name: 'PreRequisitosMotociclista',
    component: () => import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/PreRequisitosMoto.vue'),
    meta: {
      title: 'Pré-requisitos Motociclista',
    },
  },
  {
    path: '/selecionados-primeira-fase-cnh',
    name: 'SelecionadosPrimeiraFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosPrimeiraFaseCNH.vue'),
    meta: {
      title: 'Selecionados Primeira Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-segunda-fase-cnh',
    name: 'SelecionadosSegundaFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosSegundaFaseCNH.vue'),
    meta: {
      title: 'Selecionados Segunda Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-terceira-fase-cnh',
    name: 'SelecionadosTerceiraFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosTerceiraFaseCNH.vue'),
    meta: {
      title: 'Selecionados Terceira Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-quarta-fase-cnh',
    name: 'SelecionadosQuartaFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosQuartaFaseCNH.vue'),
    meta: {
      title: 'Selecionados Quarta Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-quinta-fase-cnh',
    name: 'SelecionadosQuintaFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosQuintaFaseCNH.vue'),
    meta: {
      title: 'Selecionados Quinta Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-sexta-fase-cnh',
    name: 'SelecionadosSextaFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosSextaFaseCNH.vue'),
    meta: {
      title: 'Selecionados Sexta Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-setima-fase-cnh',
    name: 'SelecionadosSetimaFaseCNH',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosSetimaFaseCNH.vue'),
    meta: {
      title: 'Selecionados Sétima Fase CNH Social 2022',
    },
  },
  {
    path: '/selecionados-primeira-fase-cnh-2023',
    name: 'SelecionadosPrimeiraFaseCNH2023',
    component: () =>
      import(/* webpackChunkName: "prerequisitos-motociclista" */ '../views/SelecionadosPrimeiraFaseCNH2023.vue'),
    meta: {
      title: 'Selecionados Primeira Fase CNH Social 2023',
    },
  },
  {
    path: '/testes',
    name: 'Testes',
    component: () => import(/* webpackChunkName: "testes" */ '../views/Testes.vue'),
    meta: {
      title: 'Testes',
    },
  },
  {
    path: '/videos',
    name: 'Vídeos',
    component: () => import(/* webpackChunkName: "testes" */ '../views/Videos.vue'),
    meta: {
      title: 'Vídeos',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
  if (to.path.indexOf('cadastro') > -1) {
    await actionsGeral.setStep(0);
    await actionsCandidate.reset();
  }
  next();
});

export default router;
