export default {
  get_inscricoes_ativas(): boolean {
    const dataLancamento = new Date(2023, 6, 20, 0, 0, 0);
    const today = new Date();
    return today > dataLancamento;
  },
  get_municipios_primeira_fase_2023(): { nome: string; link: string }[] {
    const prefix = 'files/primeira-fase-2023/CNHSOCIAL_01_2023_'

    return [
      {
        nome: 'Alvarães',
        link: `${prefix}ALVARAES.pdf`,
      },
      {
        nome: 'Anamã',
        link: `${prefix}ANAMA.pdf`,
      },
      {
        nome: 'Anori',
        link: `${prefix}ANORI.pdf`,
      },
      {
        nome: 'Apuí',
        link: `${prefix}APUI.pdf`,
      },
      {
        nome: 'Autazes',
        link: `${prefix}AUTAZES.pdf`,
      },
      {
        nome: 'Barcelos',
        link: `${prefix}BARCELOS.pdf`,
      },
      {
        nome: 'Barreirinha',
        link: `${prefix}BARREIRINHA.pdf`,
      },
      {
        nome: 'Benjamin Constant',
        link: `${prefix}BENJAMIN_CONSTANT.pdf`,
      },
      {
        nome: 'Beruri',
        link: `${prefix}BERURI.pdf`,
      },
      {
        nome: 'Boa Vista do Ramos',
        link: `${prefix}BOA_VISTA_DO_RAMOS.pdf`,
      },
      {
        nome: 'Boca do Acre',
        link: `${prefix}BOCA_DO_ACRE.pdf`,
      },
      {
        nome: 'Borba',
        link: `${prefix}BORBA.pdf`,
      },
      {
        nome: 'Caapiranga',
        link: `${prefix}CAAPIRANGA.pdf`,
      },
      {
        nome: 'Canutama',
        link: `${prefix}CANUTAMA.pdf`,
      },
      {
        nome: 'Carauari',
        link: `${prefix}CARAUARI.pdf`,
      },
      {
        nome: 'Careiro',
        link: `${prefix}CAREIRO.pdf`,
      },
      {
        nome: 'Careiro da Várzea',
        link: `${prefix}CAREIRO_DA_VARZEA.pdf`,
      },
      {
        nome: 'Coari',
        link: `${prefix}COARI.pdf`,
      },
      {
        nome: 'Codajás',
        link: `${prefix}CODAJAS.pdf`,
      },
      {
        nome: 'Eirunepé',
        link: `${prefix}EIRUNEPE.pdf`,
      },
      {
        nome: 'Envira',
        link: `${prefix}ENVIRA.pdf`,
      },
      {
        nome: 'Guajará',
        link: `${prefix}GUAJARA.pdf`,
      },
      {
        nome: 'Humaitá',
        link: `${prefix}HUMAITA.pdf`,
      },
      {
        nome: 'Ipixuna',
        link: `${prefix}IPIXUNA.pdf`,
      },
      {
        nome: 'Iranduba',
        link: `${prefix}IRANDUBA.pdf`,
      },
      {
        nome: 'Itacoatiara',
        link:  `${prefix}ITACOATIARA.pdf`,
      },
      {
        nome: 'Itamarati',
        link: `${prefix}ITAMARATI.pdf`,
      },
      {
        nome: 'Itapiranga',
        link: `${prefix}ITAPIRANGA.pdf`,
      },
      {
        nome: 'Japurá',
        link: `${prefix}JAPURA.pdf`,
      },
      {
        nome: 'Juruá',
        link: `${prefix}JURUA.pdf`,
      },
      {
        nome: 'Jutaí',
        link: `${prefix}JUTAI.pdf`,
      },
      {
        nome: 'Lábrea',
        link: `${prefix}LABREA.pdf`,
      },
      {
        nome: 'Manacapuru',
        link: `${prefix}MANACAPURU.pdf`,
      },
      {
        nome: 'Manaquiri',
        link: `${prefix}MANAQUIRI.pdf`,
      },
      {
        nome: 'Manicoré',
        link: `${prefix}MANICORE.pdf`,
      },
      {
        nome: 'Maraã',
        link: `${prefix}MARAA.pdf`,
      },
      {
        nome: 'Maués',
        link: `${prefix}MAUES.pdf`,
      },
      {
        nome: 'Nhamundá',
        link: `${prefix}NHAMUNDA.pdf`,
      },
      {
        nome: 'Nova Olinda do Norte',
        link: `${prefix}NOVA_OLINDA_DO_NORTE.pdf`,
      },
      {
        nome: 'Novo Airão',
        link: `${prefix}NOVO_AIRAO.pdf`,
      },
      {
        nome: 'Novo Aripuanã',
        link: `${prefix}NOVO_ARIPUANA.pdf`,
      },
      {
        nome: 'Parintins',
        link: `${prefix}PARINTINS.pdf`,
      },
      {
        nome: 'Pauini',
        link: `${prefix}PAUINI.pdf`,
      },
      {
        nome: 'Presidente Figueiredo',
        link: `${prefix}PRESIDENTE_FIGUEIREDO.pdf`,
      },
      {
        nome: 'Rio Preto da Eva',
        link: `${prefix}RIO_PRETO_DA_EVA.pdf`,
      },
      {
        nome: 'Santa Isabel do Rio Negro',
        link: `${prefix}SANTA_ISABEL_DO_RIO_NEGRO.pdf`,
      },
      {
        nome: 'Santo Antônio do Içá',
        link: `${prefix}SANTO_ANTONIO_DO_ICA.pdf`,
      },
      {
        nome: 'São Gabriel da Cachoeira',
        link: `${prefix}SAO_GABRIEL_DA_CACHOEIRA.pdf`,
      },
      {
        nome: 'São Sebastião do Uatumã',
        link: `${prefix}SAO_SEBASTIAO_DO_UATUMA.pdf`,
      },
      {
        nome: 'Silves',
        link: `${prefix}SILVES.pdf`,
      },
      {
        nome: 'Tabatinga',
        link: `${prefix}TABATINGA.pdf`,
      },
      {
        nome: 'Tapauá',
        link: `${prefix}TAPAUA.pdf`,
      },
      {
        nome: 'Tefé',
        link: `${prefix}TEFE.pdf`,
      },
      {
        nome: 'Tonantins',
        link: `${prefix}TONANTINS.pdf`,
      },
      {
        nome: 'Uarini',
        link: `${prefix}UARINI.pdf`,
      },
      {
        nome: 'Urucará',
        link: `${prefix}URUCARA.pdf`,
      },
      {
        nome: 'Urucurituba',
        link: `${prefix}URUCURITUBA.pdf`,
      },
    ];
  },
  get_municipios_setima_fase(): { nome: string; link: string }[] {
    return [
      {
        nome: 'Alvarães',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_ALVARAES.pdf',
      },
      {
        nome: 'Amaturá',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_AMATURA.pdf',
      },
      {
        nome: 'Anori',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_ANORI.pdf',
      },
      {
        nome: 'Apuí',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_APUI.pdf',
      },
      {
        nome: 'Atalaia do Norte',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_ATALAIA.pdf',
      },
      {
        nome: 'Autazes',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_AUTAZES.pdf',
      },
      {
        nome: 'Barcelos',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BARCELOS.pdf',
      },
      {
        nome: 'Barreirinha',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BARREIRINHA.pdf',
      },
      {
        nome: 'Benjamin Constant',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BENJAMIN_CONSTANT.pdf',
      },
      {
        nome: 'Beruri',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BERURI.pdf',
      },
      {
        nome: 'Boa Vista do Ramos',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BOA_VISTA_RAMOS.pdf',
      },
      {
        nome: 'Boca do Acre',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BOCA_DO_ACRE.pdf',
      },
      {
        nome: 'Borba',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_BORBA.pdf',
      },
      {
        nome: 'Caapiranga',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_CAAPIRANGA.pdf',
      },
      {
        nome: 'Carauari',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_CARAUARI.pdf',
      },
      {
        nome: 'Careiro',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_CAREIRO.pdf',
      },
      {
        nome: 'Careiro da Várzea',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_CAREIRO_VARZEA.pdf',
      },
      {
        nome: 'Coari',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_COARI.pdf',
      },
      {
        nome: 'Codajás',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_CODAJAS.pdf',
      },
      {
        nome: 'Eirunepé',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_EIRUNEPE.pdf',
      },
      {
        nome: 'Envira',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_ENVIRA.pdf',
      },
      {
        nome: 'Guajará',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_GUAJARA.pdf',
      },
      {
        nome: 'Humaitá',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_HUMAITA.pdf',
      },
      {
        nome: 'Ipixuna',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_IPIXUNA.pdf',
      },
      {
        nome: 'Iranduba',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_IRANDUBA.pdf',
      },
      {
        nome: 'Itacoatiara',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_ITACOATIARA.pdf',
      },
      {
        nome: 'Itapiranga',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_ITAPIRANGA.pdf',
      },
      {
        nome: 'Juruá',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_JURUA.pdf',
      },
      {
        nome: 'Lábrea',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_LABREA.pdf',
      },
      {
        nome: 'Manacapuru',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_MANACAPURU.pdf',
      },
      {
        nome: 'Manaquiri',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_MANAQUIRI.pdf',
      },
      {
        nome: 'Manicoré',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_MANICORE.pdf',
      },
      {
        nome: 'Maraã',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_MARAA.pdf',
      },
      {
        nome: 'Maués',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_MAUES.pdf',
      },
      {
        nome: 'Nhamundá',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_NHAMUNDA.pdf',
      },
      {
        nome: 'Nova Olinda do Norte',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_NOVA_OLINDA_DO_NORTE.pdf',
      },
      {
        nome: 'Novo Airão',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_NOVO_AIRAO.pdf',
      },
      {
        nome: 'Novo Aripuanã',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_NOVO_ARIPUANA.pdf',
      },
      {
        nome: 'Parintins',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_PARINTINS.pdf',
      },
      {
        nome: 'Pauini',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_PAUINI.pdf',
      },
      {
        nome: 'Presidente Figueiredo',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_PRESIDENTE_FIGUEIREDO.pdf',
      },
      {
        nome: 'Rio Preto da Eva',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_RIO_PRETO.pdf',
      },
      {
        nome: 'Santa Isabel do Rio Negro',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_SANTA_ISABEL.pdf',
      },
      {
        nome: 'Santo Antônio do Içá',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_SANTO_ANTONIO_ICA.pdf',
      },
      {
        nome: 'São Gabriel da Cachoeira',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_SAO_GABRIEL.pdf',
      },
      {
        nome: 'São Paulo de Olivença',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_SAO_PAULO_OLIVENCA.pdf',
      },
      {
        nome: 'São Sebastião do Uatumã',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_SAO_SEBASTIAO_UATUMA.pdf',
      },
      {
        nome: 'Silves',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_SILVES.pdf',
      },
      {
        nome: 'Tabatinga',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_TABATINGA.pdf',
      },
      {
        nome: 'Tefé',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_TEFE.pdf',
      },
      {
        nome: 'Tonantins',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_TONANTINS.pdf',
      },
      {
        nome: 'Uarini',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_UARINI.pdf',
      },
      {
        nome: 'Urucará',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_URUCARA.pdf',
      },
      {
        nome: 'Urucurituba',
        link: 'files/setima-fase/CANDIDATOS_SETIMA_FASE_URUCURITUBA.pdf',
      },
    ];
  },
  get_municipios_quinta_fase(): { nome: string; link: string }[] {
    return [
      { nome: 'Autazes', link: 'files/quinta-fase/AUTAZES.pdf' },
      { nome: 'Barcelos', link: 'files/quinta-fase/BARCELOS.pdf' },
      { nome: 'Barreirinha', link: 'files/quinta-fase/BARREIRINHA.pdf' },
      {
        nome: 'Benjamin Constant',
        link: 'files/quinta-fase/BENJAMIN_CONSTANT.pdf',
      },
      { nome: 'Beruri', link: 'files/quinta-fase/BERURI.pdf' },
      {
        nome: 'Boa Vista do Ramos',
        link: 'files/quinta-fase/BOA_VISTA_DO_RAMOS.pdf',
      },
      { nome: 'Boca do Acre', link: 'files/quinta-fase/BOCA_DO_ACRE.pdf' },
      { nome: 'Borba', link: 'files/quinta-fase/BORBA.pdf' },
      { nome: 'Caapiranga', link: 'files/quinta-fase/CAAPIRANGA.pdf' },
      { nome: 'Carauari', link: 'files/quinta-fase/CARAUARI.pdf' },
      { nome: 'Careiro', link: 'files/quinta-fase/CAREIRO.pdf' },
      { nome: 'Coari', link: 'files/quinta-fase/COARI.pdf' },
      { nome: 'Codajás', link: 'files/quinta-fase/CODAJAS.pdf' },
      { nome: 'Eirunepé', link: 'files/quinta-fase/EIRUNEPE.pdf' },
      { nome: 'Envira', link: 'files/quinta-fase/ENVIRA.pdf' },
      { nome: 'Guajará', link: 'files/quinta-fase/GUAJARA.pdf' },
      { nome: 'Humaitá', link: 'files/quinta-fase/HUMAITA.pdf' },
      { nome: 'Ipixuna', link: 'files/quinta-fase/IPIXUNA.pdf' },
      { nome: 'Iranduba', link: 'files/quinta-fase/IRANDUBA.pdf' },
      { nome: 'Itacoatiara', link: 'files/quinta-fase/ITACOATIARA.pdf' },
      { nome: 'Itapiranga', link: 'files/quinta-fase/ITAPIRANGA.pdf' },
      { nome: 'Juruá', link: 'files/quinta-fase/JURUA.pdf' },
      { nome: 'Lábrea', link: 'files/quinta-fase/LABREA.pdf' },
      { nome: 'Manacapuru', link: 'files/quinta-fase/MANACAPURU.pdf' },
      { nome: 'Manaquiri', link: 'files/quinta-fase/MANAQUIRI.pdf' },
      { nome: 'Manicoré', link: 'files/quinta-fase/MANICORE.pdf' },
      { nome: 'Maraã', link: 'files/quinta-fase/MARAÃ.pdf' },
      { nome: 'Maués', link: 'files/quinta-fase/MAUES.pdf' },
      { nome: 'Nhamundá', link: 'files/quinta-fase/NHAMUNDA.pdf' },
      {
        nome: 'Nova Olinda do Norte',
        link: 'files/quinta-fase/NOVA_OLINDA_DO_NORTE.pdf',
      },
      { nome: 'Novo Airão', link: 'files/quinta-fase/NOVO_AIRAO.pdf' },
      { nome: 'Novo Aripuanã', link: 'files/quinta-fase/NOVO_ARIPUANA.pdf' },
      { nome: 'Parintins', link: 'files/quinta-fase/PARINTINS.pdf' },
      { nome: 'Pauini', link: 'files/quinta-fase/PAUINI.pdf' },
      {
        nome: 'Presidente Figueiredo',
        link: 'files/quinta-fase/PRESIDENTE_FIGUEIREDO.pdf',
      },
      {
        nome: 'Rio Preto da Eva',
        link: 'files/quinta-fase/RIO_PRETO_DA_EVA.pdf',
      },
      {
        nome: 'Santa Isabel do Rio Negro',
        link: 'files/quinta-fase/SANTA_ISABEL_DO_RIO_NEGRO.pdf',
      },
      {
        nome: 'Santo Antônio do Içá',
        link: 'files/quinta-fase/SANTO_ANTONIO_DO_ICA.pdf',
      },
      {
        nome: 'São Gabriel da Cachoeira',
        link: 'files/quinta-fase/SAO_GABRIEL_DA_CACHOEIRA.pdf',
      },
      {
        nome: 'São Sebastião do Uatumã',
        link: 'files/quinta-fase/SAO_SEBASTIAO_DO_UATUMA.pdf',
      },
      { nome: 'Silves', link: 'files/quinta-fase/SILVES.pdf' },
      { nome: 'Tabatinga', link: 'files/quinta-fase/TABATINGA.pdf' },
      // { nome: "Tapauá", link: "files/quinta-fase/TAPAUA.pdf" },
      { nome: 'Tefé', link: 'files/quinta-fase/TEFE.pdf' },
      { nome: 'Tonantins', link: 'files/quinta-fase/TONANTINS.pdf' },
      { nome: 'Uarini', link: 'files/quinta-fase/UARINI.pdf' },
      { nome: 'Urucará', link: 'files/quinta-fase/URUCARA.pdf' },
      { nome: 'Urucurituba', link: 'files/quinta-fase/URUCURITUBA.pdf' },
    ];
  },
  get_municipios_terceira_fase(): { nome: string; link: string }[] {
    return [
      {
        nome: 'Alvarães',
        link: 'files/terceiro_processo_seletivo_alvaraes.pdf',
      },
      { nome: 'Amaturá', link: 'files/terceiro_processo_seletivo_amatura.pdf' },
      { nome: 'Anamã', link: 'files/terceiro_processo_seletivo_anama.pdf' },
      { nome: 'Anori', link: 'files/terceiro_processo_seletivo_anori.pdf' },
      { nome: 'Apuí', link: 'files/terceiro_processo_seletivo_apui.pdf' },
      {
        nome: 'Atalaia do Norte',
        link: 'files/terceiro_processo_seletivo_atalaia.pdf',
      },
      { nome: 'Autazes', link: 'files/terceiro_processo_seletivo_autazes.pdf' },
      {
        nome: 'Barcelos',
        link: 'files/terceiro_processo_seletivo_barcelos.pdf',
      },
      {
        nome: 'Barreirinha',
        link: 'files/terceiro_processo_seletivo_barreirinha.pdf',
      },
      {
        nome: 'Benjamin Constant',
        link: 'files/terceiro_processo_seletivo_benjamin_constant.pdf',
      },
      { nome: 'Beruri', link: 'files/terceiro_processo_seletivo_beruri.pdf' },
      {
        nome: 'Boa Vista do Ramos',
        link: 'files/terceiro_processo_seletivo_boa_vista_do_ramos.pdf',
      },
      {
        nome: 'Boca do Acre',
        link: 'files/terceiro_processo_seletivo_boca_do_acre.pdf',
      },
      { nome: 'Borba', link: 'files/terceiro_processo_seletivo_borba.pdf' },
      {
        nome: 'Caapiranga',
        link: 'files/terceiro_processo_seletivo_caapiranga.pdf',
      },
      {
        nome: 'Canutama',
        link: 'files/terceiro_processo_seletivo_canutama.pdf',
      },
      {
        nome: 'Carauari',
        link: 'files/terceiro_processo_seletivo_carauari.pdf',
      },
      { nome: 'Careiro', link: 'files/terceiro_processo_seletivo_careiro.pdf' },
      {
        nome: 'Careiro da Várzea',
        link: 'files/terceiro_processo_seletivo_careiro_da_varzea.pdf',
      },
      { nome: 'Coari', link: 'files/terceiro_processo_seletivo_coari.pdf' },
      { nome: 'Codajás', link: 'files/terceiro_processo_seletivo_codajas.pdf' },
      {
        nome: 'Eirunepé',
        link: 'files/terceiro_processo_seletivo_eirunepe.pdf',
      },
      { nome: 'Envira', link: 'files/terceiro_processo_seletivo_envira.pdf' },
      {
        nome: 'Fonte Boa',
        link: 'files/terceiro_processo_seletivo_fonte_boa.pdf',
      },
      { nome: 'Guajará', link: 'files/terceiro_processo_seletivo_guajará.pdf' },
      { nome: 'Humaitá', link: 'files/terceiro_processo_seletivo_humaita.pdf' },
      { nome: 'Ipixuna', link: 'files/terceiro_processo_seletivo_ipixuna.pdf' },
      {
        nome: 'Iranduba',
        link: 'files/terceiro_processo_seletivo_iranduba.pdf',
      },
      {
        nome: 'Itacoatiara',
        link: 'files/terceiro_processo_seletivo_itacoatiara.pdf',
      },
      {
        nome: 'Itamarati',
        link: 'files/terceiro_processo_seletivo_itamarati.pdf',
      },
      {
        nome: 'Itapiranga',
        link: 'files/terceiro_processo_seletivo_itapiranga.pdf',
      },
      { nome: 'Juruá', link: 'files/terceiro_processo_seletivo_jurua.pdf' },
      { nome: 'Jutaí', link: 'files/terceiro_processo_seletivo_jutai.pdf' },
      { nome: 'Lábrea', link: 'files/terceiro_processo_seletivo_labrea.pdf' },
      {
        nome: 'Manacapuru',
        link: 'files/terceiro_processo_seletivo_manacapuru.pdf',
      },
      {
        nome: 'Manaquiri',
        link: 'files/terceiro_processo_seletivo_manaquiri.pdf',
      },
      {
        nome: 'Manicoré',
        link: 'files/terceiro_processo_seletivo_manicore.pdf',
      },
      { nome: 'Maraã', link: 'files/terceiro_processo_seletivo_maraa.pdf' },
      { nome: 'Maués', link: 'files/terceiro_processo_seletivo_maues.pdf' },
      {
        nome: 'Nhamundá',
        link: 'files/terceiro_processo_seletivo_nhamunda.pdf',
      },
      {
        nome: 'Nova Olinda do Norte',
        link: 'files/terceiro_processo_seletivo_nova_olinda_do_norte.pdf',
      },
      {
        nome: 'Novo Airão',
        link: 'files/terceiro_processo_seletivo_novo_airao.pdf',
      },
      {
        nome: 'Novo Aripuanã',
        link: 'files/terceiro_processo_seletivo_novo_aripuana.pdf',
      },
      {
        nome: 'Parintins',
        link: 'files/terceiro_processo_seletivo_parintins.pdf',
      },
      { nome: 'Pauini', link: 'files/terceiro_processo_seletivo_pauini.pdf' },
      {
        nome: 'Presidente Figueiredo',
        link: 'files/terceiro_processo_seletivo_presidente_figueiredo.pdf',
      },
      {
        nome: 'Rio Preto da Eva',
        link: 'files/terceiro_processo_seletivo_rio_preto_da_eva.pdf',
      },
      {
        nome: 'Santa Isabel do Rio Negro',
        link: 'files/terceiro_processo_seletivo_santa_isabel_do_rio_negro.pdf',
      },
      {
        nome: 'Santo Antônio do Içá',
        link: 'files/terceiro_processo_seletivo_santo_antonio_do_ica.pdf',
      },
      {
        nome: 'São Gabriel da Cachoeira',
        link: 'files/terceiro_processo_seletivo_sao_gabriel_da_cachoeira.pdf',
      },
      {
        nome: 'São Paulo de Olivença',
        link: 'files/terceiro_processo_seletivo_sao_paulo_de_olivenca.pdf',
      },
      {
        nome: 'São Sebastião do Uatumã',
        link: 'files/terceiro_processo_seletivo_sao_sebastiao_do_uatuma.pdf',
      },
      { nome: 'Silves', link: 'files/terceiro_processo_seletivo_silves.pdf' },
      {
        nome: 'Tabatinga',
        link: 'files/terceiro_processo_seletivo_tabatinga.pdf',
      },
      { nome: 'Tapauá', link: 'files/terceiro_processo_seletivo_tapaua.pdf' },
      { nome: 'Tefé', link: 'files/terceiro_processo_seletivo_tefe.pdf' },
      {
        nome: 'Tonantins',
        link: 'files/terceiro_processo_seletivo_tonantins.pdf',
      },
      { nome: 'Uarini', link: 'files/terceiro_processo_seletivo_uarini.pdf' },
      { nome: 'Urucará', link: 'files/terceiro_processo_seletivo_urucara.pdf' },
      {
        nome: 'Urucurituba',
        link: 'files/terceiro_processo_seletivo_urucurituba.pdf',
      },
    ];
  },
  get_municipios_segunda_fase(): { nome: string; link: string }[] {
    return [
      { nome: 'Autazes', link: 'files/segundo_processo_seletivo_autazes.pdf' },
      { nome: 'Careiro', link: 'files/segundo_processo_seletivo_careiro.pdf' },
      {
        nome: 'Careiro da várzea',
        link: 'files/segundo_processo_seletivo_careiro_da_varzea.pdf',
      },
      { nome: 'Coari', link: 'files/segundo_processo_seletivo_coari.pdf' },
      { nome: 'Humaitá', link: 'files/segundo_processo_seletivo_humaita.pdf' },
      {
        nome: 'Iranduba',
        link: 'files/segundo_processo_seletivo_iranduba.pdf',
      },
      {
        nome: 'Itacoatiara',
        link: 'files/segundo_processo_seletivo_itacoatiara.pdf',
      },
      {
        nome: 'Itapiranga',
        link: 'files/segundo_processo_seletivo_itapiranga.pdf',
      },
      {
        nome: 'Manacapuru',
        link: 'files/segundo_processo_seletivo_manacapuru.pdf',
      },
      {
        nome: 'Manaquiri',
        link: 'files/segundo_processo_seletivo_manaquiri.pdf',
      },
      { nome: 'Manaus', link: 'files/segundo_processo_seletivo_manaus.pdf' },
      {
        nome: 'Novo Airão',
        link: 'files/segundo_processo_seletivo_novo_airao.pdf',
      },
      {
        nome: 'Parintins',
        link: 'files/segundo_processo_seletivo_parintins.pdf',
      },
      {
        nome: 'Presidente Figueiredo',
        link: 'files/segundo_processo_seletivo_presidente_figueiredo.pdf',
      },
      {
        nome: 'Rio Preto da Eva',
        link: 'files/segundo_processo_seletivo_rio_preto_da_eva.pdf',
      },
      { nome: 'Silves', link: 'files/segundo_processo_seletivo_silves.pdf' },
      {
        nome: 'Tabatinga',
        link: 'files/segundo_processo_seletivo_tabatinga.pdf',
      },
      { nome: 'Tefé', link: 'files/segundo_processo_seletivo_tefe.pdf' },
    ];
  },
  trackClick(category: string, label: string): void {
    window.gtag('event', 'click', {
      event_category: category,
      event_label: label,
    });
  },
};
