import { computed, reactive } from 'vue';
import ServicoHabilitacao from './entities/servicoHabilitacao';

const servicosHabilitacao: ServicoHabilitacao[] | [] = [
  { id: 'S1', descricao: 'Primeira Habilitação' },
  { id: 'S2', descricao: 'Adição de Categoria' },
  { id: 'S3', descricao: 'Troca de Categoria' },
];

const state = reactive({
  step: 0,
  showModalNotification: false,
  componentName: ['CmpIdentificacao', 'CmpDados', 'CmpEndereco', 'CmpConfirmacao'],
  servicosHabilitacao: servicosHabilitacao,
});

const getters = {
  step: computed(() => state.step),
  showModalNotification: computed(() => state.showModalNotification),
  selectedComponent: computed(() => state.componentName[state.step]),
  servicosHabilitacao: computed(() => state.servicosHabilitacao),
};

const actions = {
  priorStep: async (): Promise<void> => {
    if (state.step > 0) state.step -= 1;
  },
  nextStep: async (): Promise<void> => {
    if (state.step < 4) state.step += 1;
  },
  setStep: async (step: number): Promise<void> => {
    if (step in [0, 1, 2, 3]) {
      state.step = step;
    }
  },
  setModalNotification: (value: boolean): void => {
    state.showModalNotification = value;
  },
  findByIdServicoHabilitacao: (id: string): [] => {
    const servicos = state.servicosHabilitacao;
    const res = servicos.filter((s) => s.id == id);
    return JSON.parse(JSON.stringify(res));
  },
};

export { state, getters, actions };
