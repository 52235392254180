import { parseISO, parse, isValid, format } from 'date-fns';
import { enGB } from 'date-fns/locale';

export default class Mascara {
  somenteNumeros(num: string): string {
    return num.replace(/[^\d]+/g, '');
  }

  formatCPF = (cpf: string): string => {
    if (!cpf) {
      return cpf;
    }
    if (cpf.length > 2) {
      return `${cpf
        .substr(0, cpf.length - 2)
        .split(/(?=(?:...)*$)/)
        .join('.')}-${cpf.substr(-2)}`;
    } else {
      return cpf;
    }
  };

  validarCPF(cpf: string): boolean {
    if (cpf == null) {
      return false;
    }
    if (cpf.length != 11) {
      return false;
    }
    if (
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    ) {
      return false;
    }
    let numero = 0;
    let caracter = '';
    const numeros = '0123456789';
    let j = 10;
    let somatorio = 0;
    let resto = 0;
    let digito1 = 0;
    let digito2 = 0;
    let cpfAux = '';
    cpfAux = cpf.substring(0, 9);
    for (let i = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + numero * j;
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + numero * j;
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    } else {
      return true;
    }
  }

  isValidEmail(email: string): boolean {
    const mailformat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  isValidDate(day: string, month: string, year: string): boolean {
    const dia = '00'.concat(day).substr(-2, 2);
    const mes = '00'.concat(month).substr(-2, 2);
    const ano = '0000'.concat(year).substr(-4, 4);
    const parsed = parse(`${dia}/${mes}/${ano}`, 'P', new Date(), {
      locale: enGB,
    });
    return isValid(parsed);
  }

  ValidaIdade18 = (s: string): boolean => {
    // formato data "dd/mm/yyyy"
    if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
      return false;
    }
    const parts = s.split('/').map((p) => parseInt(p, 10));
    parts[1] -= 1;
    const d = new Date(parts[2], parts[1], parts[0]);

    const birthday = +new Date(d);
    const idade = ~~((Date.now() - birthday) / 31557600000);
    return idade >= 18;
  };

  temNomeMinimo(nome: string): boolean {
    const espaco = ' ';
    const minPalavras = 2; // Qtde Mínima de Palavras no Nome
    const minCaracteres = 5; // Qtde de carateres mínimos
    const arr = nome.trim().split(espaco);
    let cont = 0;

    if (arr.length >= minPalavras) {
      arr.forEach((element) => {
        if (element.length > 0) {
          cont += element.length;
        }
      });
    }
    return cont >= minCaracteres ? true : false;
  }

  formatRG(rg: string): string {
    if (!rg) {
      return rg;
    }
    return `${rg.substr(0, rg.length - 1)}-${rg.substr(-1)}`;
  }

  formatCodAluno(codigo: string): string {
    if (!codigo) {
      return codigo;
    }
    codigo = codigo.toString();
    if (codigo.toString().indexOf('-') > -1) {
      codigo = codigo.split('-').join('');
    }
    return `${codigo.substr(0, codigo.length - 1)}-${codigo.substr(-1)}`;
  }

  formatDateBR(dateOri: string): string {
    const dtParse = parseISO(dateOri); // yyyy-mm-dd
    const fmtDate = format(dtParse, 'dd/MM/yyyy');
    return fmtDate;
  }

  formatDateEN(dateOri: string): string {
    let tmp = [''];
    if (dateOri.indexOf('/') != -1) tmp = dateOri.split('/');
    if (dateOri.indexOf('-') != -1) tmp = dateOri.split('-');
    const dia = '00'.concat(tmp[0]).substr(-2, 2);
    const mes = '00'.concat(tmp[1]).substr(-2, 2);
    const ano = '0000'.concat(tmp[2]).substr(-4, 4);
    const dateEN = ano + '-' + mes + '-' + dia;
    const dtParse = parseISO(dateEN);
    const fmtDate = format(dtParse, 'yyyy-MM-dd');
    return fmtDate;
  }

  formatPhone(phone: string): string {
    let phoneString = phone.toString();
    phoneString = phoneString.replace(/\D/g, '');
    phoneString = phoneString.replace(/^(\d{2})(\d)/g, '($1) $2');
    phoneString = phoneString.replace(/(\d)(\d{4})$/, '$1-$2');
    return phoneString;
  }

  formatCEP(cep: string): string {
    if (!cep) {
      return cep;
    }
    return `${cep.substr(0, cep.length - 3)}-${cep.substr(-3)}`;
  }
}
