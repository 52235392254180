import { computed, reactive } from 'vue';
// import Candidate from "./entities/candidate";
import MaskOutput from '@/tools/masks';
import CandidateRepositoryAPI from '@/modules/candidate/repository/CandidateRepositoryAPI';
import ServiceCandidate from './serviceCandidate';
import PayloadInsertCandidate from './entities/payloadInsertCandidate';
// import HTTP from "@/axios-auth";
const masks = new MaskOutput();

const initialState = {
  foundCandidate: false,
  insertSuccessCandidate: 0, // 0 neutro | 1 - sucesso | 2 - falhou
  insertDate: '',
  insertHour: '',
  insertMessage: '',
  candidate: {
    cpf: {
      value: '',
      dirty: false,
    },
    dataNascimento: {
      value: '',
      dirty: false,
    },
    nome: {
      value: '',
      dirty: false,
    },
    // rg: {
    //   value: "",
    //   dirty: false,
    // },
    telefone: {
      value: '',
      dirty: false,
    },
    email: {
      value: '',
      dirty: false,
    },
    servicoHabilitacao: {
      value: '',
      dirty: false,
    },
    categoriaHabilitacao: {
      value: '',
      dirty: false,
    },
    cep: {
      value: '',
      dirty: false,
    },
    municipio: {
      value: '',
      dirty: false,
    },
    tipoLogradouro: {
      value: '',
      dirty: false,
    },
    logradouro: {
      value: '',
      dirty: false,
    },
    numero: {
      value: '',
      dirty: false,
    },
    complemento: {
      value: '',
      dirty: false,
    },
    bairro: {
      value: '',
      dirty: false,
    },
  },
};

const state = reactive(initialState);

const getters = {
  candidate: computed(() => state.candidate),
  foundCandidate: computed(() => state.foundCandidate),
  msgErrorNome: computed(() => {
    if (!state.candidate.nome.dirty) return false;
    if (!state.candidate.nome.value) return 'Campo obrigarório';
    if (!masks.temNomeMinimo(state.candidate.nome.value)) return 'Mínimo de 2 nomes e 5 caracteres';
    return false;
  }),
  msgErrorDataNascimento: computed(() => {
    if (!state.candidate.dataNascimento.dirty) return false;
    if (!state.candidate.dataNascimento.value) return 'Campo obrigatório';
    if (state.candidate.dataNascimento.value.length < 10) return `Data inválida`;
    const [dia, mes, ano] = state.candidate.dataNascimento.value.split('/');
    if (!masks.isValidDate(dia, mes, ano)) {
      return `Data inválida`;
    } else if (!masks.ValidaIdade18(state.candidate.dataNascimento.value)) {
      return `Você precisa ser maior de 18 anos.`;
    } else {
      return false;
    }
  }),
  msgErroCpf: computed(() => {
    if (!state.candidate.cpf.dirty) return false;
    if (!state.candidate.cpf.value) return 'Campo obrigarório';
    const cpfNum = masks.somenteNumeros(state.candidate.cpf.value);
    if (cpfNum.length < 11) return 'CPF Inválido';
    if (!masks.validarCPF(cpfNum)) return 'CPF Inválido';
    return false;
  }),
  msgErrorEmail: computed(() => {
    if (!state.candidate.email.dirty) return false;
    if (state.candidate.email.value) {
      if (!masks.isValidEmail(state.candidate.email.value)) return 'Email inválido';
    }
    return false;
  }),
  msgErrorTelefone: computed(() => {
    if (!state.candidate.telefone.dirty) return false;
    if (!state.candidate.telefone.value) return 'Campo obrigatório';

    const telefone = masks.somenteNumeros(state.candidate.telefone.value);

    if (telefone.length != 11) return 'Telefone inválido';

    return false;
  }),
  msgErrorServicoHabilitacao: computed(() => {
    if (!state.candidate.servicoHabilitacao.dirty) return false;
    if (!state.candidate.servicoHabilitacao.value) return 'Campo obrigatório';
    return false;
  }),
  msgErrorCategoriaHabilitacao: computed(() => {
    if (!state.candidate.categoriaHabilitacao.dirty) return false;
    if (!state.candidate.categoriaHabilitacao.value) return 'Campo obrigatório';
    return false;
  }),
  msgErrorCEP: computed(() => {
    if (!state.candidate.cep.dirty) return false;
    if (!state.candidate.cep.value) return 'Campo obrigatório';
    const cpfNum = masks.somenteNumeros(state.candidate.cep.value);
    if (cpfNum.length !== 8) return 'CEP inválido';
    return false;
  }),
  msgErrorMunicipio: computed(() => {
    if (!state.candidate.municipio.dirty) return false;
    if (!state.candidate.municipio.value) return 'Campo obrigatório.';
    return false;
  }),
  msgErrorTipoLogradouro: computed(() => {
    if (!state.candidate.tipoLogradouro.dirty) return false;
    if (!state.candidate.tipoLogradouro.value) return 'Campo obrigatório.';
    return false;
  }),
  msgErrorLogradouro: computed(() => {
    if (!state.candidate.logradouro.dirty) return false;
    if (!state.candidate.logradouro.value) return 'Campo obrigatório.';
    return false;
  }),
  msgErrorNumero: computed(() => {
    if (!state.candidate.numero.dirty) return false;
    if (!state.candidate.numero.value) return 'Campo obrigatório.';
    return false;
  }),
  msgErrorComplemento: computed(() => {
    if (!state.candidate.complemento.dirty) return false;
    // if (!state.candidate.complemento.value) return "Campo obrigatório.";
    return false;
  }),
  msgErrorBairro: computed(() => {
    if (!state.candidate.bairro.dirty) return false;
    if (!state.candidate.bairro.value) return 'Campo obrigatório.';
    return false;
  }),
  getServicosHabilitacao: computed(() => {
    if (state.candidate.servicoHabilitacao.value) return [];
    const temp = [
      { id: 'S1', descricao: 'Primeira Habilitação S2' },
      { id: 'S2', descricao: 'Adição de Categoria' },
      { id: 'S3', descricao: 'Troca de Categoria' },
    ];
    return temp.find((a) => a.id == state.candidate.servicoHabilitacao.value);
  }),
  categoriasHabilitacao: computed(() => {
    if (!state.candidate.servicoHabilitacao.value) return [];
    switch (state.candidate.servicoHabilitacao.value) {
      case 'S1':
        return [
          { id: 'A', descricao: 'A' },
          { id: 'B', descricao: 'B' },
        ];
      case 'S2':
        return [
          { id: 'A', descricao: 'A' },
          { id: 'B', descricao: 'B' },
        ];
      case 'S3':
        return [
          { id: 'C', descricao: 'C' },
          { id: 'D', descricao: 'D' },
          { id: 'E', descricao: 'E' },
        ];
      default:
        return [];
    }
  }),
  insertSuccessCandidate: computed(() => state.insertSuccessCandidate),
  insertDate: computed(() => state.insertDate),
  insertHour: computed(() => state.insertHour),
  insertMessage: computed(() => state.insertMessage),
};

const actions = {
  reset: async (): Promise<void> => {
    state.foundCandidate = false;
    state.insertSuccessCandidate = 0;
    state.insertMessage = '';
    state.insertDate = '';
    state.insertHour = '';
    state.candidate = {
      cpf: {
        value: '',
        dirty: false,
      },
      dataNascimento: {
        value: '',
        dirty: false,
      },
      nome: {
        value: '',
        dirty: false,
      },
      // rg: {
      //   value: "",
      //   dirty: false,
      // },
      telefone: {
        value: '',
        dirty: false,
      },
      email: {
        value: '',
        dirty: false,
      },
      servicoHabilitacao: {
        value: '',
        dirty: false,
      },
      categoriaHabilitacao: {
        value: '',
        dirty: false,
      },
      cep: {
        value: '',
        dirty: false,
      },
      municipio: {
        value: '',
        dirty: false,
      },
      tipoLogradouro: {
        value: '',
        dirty: false,
      },
      logradouro: {
        value: '',
        dirty: false,
      },
      numero: {
        value: '',
        dirty: false,
      },
      complemento: {
        value: '',
        dirty: false,
      },
      bairro: {
        value: '',
        dirty: false,
      },
    };
  },
  setInsertSuccessCandidate: (value: number): void => {
    state.insertSuccessCandidate = value;
  },
  setInsertDate: (data: string): void => {
    state.insertDate = data;
  },
  setInsertHour: (data: string): void => {
    state.insertHour = data;
  },
  setInsertMessage: (data: string): void => {
    state.insertMessage = data;
  },
  findByCpfDataNascimento: async (cpf: string, dataNascimento: string): Promise<void> => {
    const candidateRepository = new CandidateRepositoryAPI();
    const serviceCandidate = new ServiceCandidate(candidateRepository);
    await serviceCandidate
      .findByCpfDataNascimento(cpf, dataNascimento)
      .then(() => {
        state.foundCandidate = true;
      })
      .catch(() => {
        state.foundCandidate = false;
      });
  },
  formatCPF: (): void => {
    if (state.candidate.cpf.value && state.candidate.cpf.dirty) {
      const cpfNum = masks.somenteNumeros(state.candidate.cpf.value);
      if (cpfNum.length == 11) {
        state.candidate.cpf.value = masks.formatCPF(cpfNum);
      } else {
        state.candidate.cpf.value = cpfNum;
      }
    }
  },
  formatCelular: (): void => {
    state.candidate.telefone.value = masks.formatPhone(state.candidate.telefone.value);
  },
  formatCEP: (): void => {
    const cepNum = masks.somenteNumeros(state.candidate.cep.value);
    if (cepNum.length == 8) state.candidate.cep.value = masks.formatCEP(cepNum);
    else state.candidate.cep.value = cepNum;
  },
  formatDateBR: (): void => {
    const data = state.candidate.dataNascimento.value;
    const dateNum = masks.somenteNumeros(data);
    if (dateNum.length == 8) {
      const dateBR = `${dateNum.substr(0, dateNum.length - 6)}/${dateNum.substr(-6, 2)}/${dateNum.substr(-4)}`;
      // const [dia, mes, ano] = dateBR.split("/");
      // const dateEN = `${ano}-${mes}-${dia}`;
      state.candidate.dataNascimento.value = dateBR;
    } else state.candidate.dataNascimento.value = dateNum;
  },
  insertCandidate: async (): Promise<void> => {
    const [dia, mes, ano] = getters.candidate.value.dataNascimento.value.split('/');
    const dateEN = `${ano}-${mes}-${dia}`;
    const payload: PayloadInsertCandidate = {
      cpf: masks.somenteNumeros(getters.candidate.value.cpf.value),
      nome: getters.candidate.value.nome.value,
      data_nascimento: dateEN, // getters.candidate.value.dataNascimento.value,
      municipio: getters.candidate.value.municipio.value,
      cep: parseFloat(masks.somenteNumeros(getters.candidate.value.cep.value)),
      tipo_logradouro: getters.candidate.value.tipoLogradouro.value,
      logradouro: getters.candidate.value.logradouro.value,
      numero: getters.candidate.value.numero.value,
      complemento: getters.candidate.value.complemento.value,
      bairro: getters.candidate.value.bairro.value,
      telefone: parseFloat(masks.somenteNumeros(getters.candidate.value.telefone.value)),
      email: getters.candidate.value.email.value,
      servico_habilitacao: getters.candidate.value.servicoHabilitacao.value,
      categoria_habilitacao: getters.candidate.value.categoriaHabilitacao.value,
    };
    const candidateRepository = new CandidateRepositoryAPI();
    const serviceCandidate = new ServiceCandidate(candidateRepository);
    await serviceCandidate
      .InsertCandidate(payload)
      .then((res) => {
        state.insertSuccessCandidate = 1;
        //console.log("Res : ", res);
        if (res.data_cadastro) state.insertDate = res.data_cadastro;
        if (res.hora_cadastro) state.insertHour = res.hora_cadastro;
      })
      .catch(() => {
        //console.log("Error :", error);
        state.insertSuccessCandidate = 2;
      });
  },
};

export { state, getters, actions };
