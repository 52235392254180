import HTTP from '@/axios-auth';
import PayloadInsertCandidate from '../entities/payloadInsertCandidate';
import ResponseCandidate from '../entities/responseCandidate';
import CandidateRepository from './CandidateRepository';
export default class CandidateRepositoryAPI implements CandidateRepository {
  async insertCandidate(candidate: PayloadInsertCandidate): Promise<ResponseCandidate> {
    const url = `/candidato`;
    const res = await HTTP.post(url, candidate);
    return res.data;
  }
  async findByCpfDataNascimento(cpf: string, dataNascimento: string): Promise<ResponseCandidate> {
    const url = `/candidato/${cpf}/${dataNascimento}`;
    const res = await HTTP.get(url);
    return res.data;
  }
}
