
import { computed, defineComponent } from 'vue';
import Cabecalho from '../components/Cabecalho.vue';
import util from '../util';

export default defineComponent({
  components: {
    Cabecalho,
  },
  setup() {
    const inscricoes_ativas = computed(() => util.get_inscricoes_ativas());
    return { inscricoes_ativas };
  },
});
