
import { defineComponent, ref } from 'vue';
import VueCarousel from '@chenfengyuan/vue-carousel';
import Menu from '../components/Menu.vue';

export default defineComponent({
  name: 'Cabecalho',
  components: {
    Menu,
    VueCarousel,
  },
  setup() {
    const sliders = [
      {
        small: 'img/sliders/primeira-fase-2023-small.png',
        medium: 'img/sliders/primeira-fase-2023-medium.png',
        large: 'img/sliders/primeira-fase-2023-large.png',
        link: '/selecionados-primeira-fase-cnh-2023',
      },
      {
        small: 'img/sliders/banner-capital-small.png',
        medium: 'img/sliders/banner-capital-medium.png',
        large: 'img/sliders/banner-capital-large.png',
        link: 'https://www.detran.am.gov.br/',
      },
      {
        small: 'img/sliders/banner-interior-small.png',
        medium: 'img/sliders/banner-interior-medium.png',
        large: 'img/sliders/banner-interior-large.png',
      },
    ].map((slide_item) => {
      const pre = slide_item.link ? `<a href="${slide_item.link}">` : ``;
      const end = slide_item.link ? `</a>` : ``;

      return (
        pre +
        `<picture>
            <source media="(max-width:639px)" srcset="${slide_item.small}" />
            <source media="(max-width:1023px)" srcset="${slide_item.medium}" />
            <source media="(max-width:1535px)" srcset="${slide_item.large}" />
            <img src="${slide_item.large}" alt="Comunicado de Atenção" class="w-full" />
          </picture>` +
        end
      );
    });

    const open = ref(false);
    const toggle = () => {
      open.value = !open.value;
    };
    const obterLink = () => {
      const now = new Date();
      const todayMidday = new Date(
        2022,
        7, // agosto
        16,
        14 // horas
      );
      // return { title: 'Confira a lista dos selecionados na segunda fase da cnh social', link: '/selecionados-segunda-fase-cnh' }
      return now.getTime() < todayMidday.getTime()
        ? {
            title: 'Confira a lista dos selecionados na terceira fase da cnh social',
            link: '/selecionados-terceira-fase-cnh',
          }
        : {
            title: 'Confira a lista dos selecionados na quarta fase da cnh social',
            link: '/selecionados-quarta-fase-cnh',
          };
    };
    return { open, toggle, obterLink, sliders };
  },
});
