
import {computed, defineComponent} from 'vue';
import util from '@/util';

export default defineComponent({
  name: 'Menu',
  setup() {
    const inscricoes_ativas = computed(() => util.get_inscricoes_ativas());
    const trackClick = util.trackClick;
    return {inscricoes_ativas, trackClick};
  },
  data: function () {
    return {
      page: '/',
      links: {
        2022: [
          {to: '/selecionados-primeira-fase-cnh', label: '1ª Fase'},
          {to: '/selecionados-segunda-fase-cnh', label: '2ª Fase'},
          {to: '/selecionados-terceira-fase-cnh', label: '3ª Fase'},
          {to: '/selecionados-quarta-fase-cnh', label: '4ª Fase'},
          {to: '/selecionados-quinta-fase-cnh', label: '5ª Fase'},
          {to: '/selecionados-sexta-fase-cnh', label: '6ª Fase'},
          {to: '/selecionados-setima-fase-cnh', label: '7ª Fase'},
        ],
        2023: [{to: '/selecionados-primeira-fase-cnh-2023', label: '1ª Fase'}],
      },
      activeTab: 2023,
    };
  },
  created() {
    this.page = this.$route.path;
    const date: { [year: number]: { to: string; label: string }[] } = this.links;
    for (const year in date) {
      if (this.isInYear(parseInt(year), this.page)) {
        this.activeTab = parseInt(year);
      }
    }
  },
  methods: {
    isInYear(year: number, searchString: string): boolean {
      const date: { [year: number]: { to: string; label: string }[] } = this.links;
      const years = date[year];
      if (years) {
        return years.some((obj) => obj.to === searchString);
      }
      return false;
    },
    changeRoute(event: Event) {
      const target = (event.currentTarget as HTMLTextAreaElement).value;
      this.trackClick('Menu', `Menu - ${this.getPages(target).label}`);
      this.$router.push(target ? target : '/');
    },
    getPages(page: string, year = 2022): { label: string; prev: string; next: string } {
      const date: { [year: number]: { to: string; label: string }[] } = this.links;
      const index = date[year].findIndex((link) => link.to === page);
      if (index > -1) {
        return {
          label: date[year][index].label,
          prev: date[year][index === 0 ? date[year].length - 1 : index - 1].to,
          next: date[year][index === date[year].length - 1 ? 0 : index + 1].to,
        };
      }
      return {
        label: '',
        prev: page,
        next: page,
      };
    },
  },
});
