import PayloadInsertCandidate from './entities/payloadInsertCandidate';
import ResponseCandidate from './entities/responseCandidate';
import CandidateRepository from './repository/CandidateRepository';

class ServiceCandidate {
  constructor(readonly candidateRepository: CandidateRepository) {}

  async findByCpfDataNascimento(cpf: string, dataNascimento: string): Promise<ResponseCandidate> {
    return await this.candidateRepository.findByCpfDataNascimento(cpf, dataNascimento);
  }
  async InsertCandidate(candidate: PayloadInsertCandidate): Promise<ResponseCandidate> {
    return await this.candidateRepository.insertCandidate(candidate);
  }
}

export default ServiceCandidate;
