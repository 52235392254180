<template>
  <div class="container flex items-center justify-center p-8 md:text-right gap-4">
    <a href="https://www.detran.am.gov.br" target="_blank">
      <img
        src="https://apstatic.prodam.am.gov.br/images/detran/logo-detran-horizontal.png"
        alt="DETRAN Amazonas "
        class="mx-auto h-10 md:mr-10 md:inline md:h-16" />
    </a>

      <a href="https://www.prodam.am.gov.br" target="_blank" class="inlin text-center md:mt-5 md:inline-block 2xl:mt-0">
        <img
          src="https://apstatic.prodam.am.gov.br/images/prodam/logo-prodam-horizontal.png"
          alt="PRODAM - Processamento de Dados Amazonas"

          class="inline w-32 md:w-48" />
      </a>
  </div>
</template>

<script></script>
